import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getUnityArticle, sendToInDesign } from '../api';
import Button from '../components/Button';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import RegenerateModal from '../components/RegenerateModal';
import ShowPDF from '../components/ShowPDF';
import config from '../config';
import { printMetadataType } from '../constants';
import { ArticleArray, ArticleCandidate } from '../types';
import { addSrcToImage, trackButtonClick } from '../utils';

interface LayoutsProps {
  location: any;
  props: any;
}

const hyperParametersTypes = [
  { popSize: 100, generation: 180, mut: 0.08, cross: 0.7 }, // default params
  { popSize: 70, generation: 130, mut: 0.07, cross: 0.6, manipulate_picuture: true },
  { popSize: 50, generation: 100, mut: 0.08, cross: 0.7, historical_search: true }
];

const Layouts: React.FC<LayoutsProps> = (props) => {
  const urlParams = new URLSearchParams(props.location.search);
  const item: any = JSON.parse(urlParams.get('item') || '');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [articles, setArticles] = useState<ArticleCandidate[][]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<ArticleArray>({ articles: [] });
  const [selectedArticle, setSelectedArticle] = useState<ArticleCandidate | null>(null);
  const [preparedGenerationData, setPreparedGenerationData] = useState();
  console.log(JSON.stringify(file));
  async function handleGenerate(newFile: any) {
    setLoading(true);
    setFile(newFile);
    const id = toast.loading('Generating the page. This can take a while ...');

    try {
      const responses = await Promise.all(
        hyperParametersTypes.map((type) => {
          return axios.post(
            `${config.backend_url}/generate_layout`,
            { ...newFile, hyperParameters: type },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
        })
      );
      const results: any[] | ((prevState: ArticleCandidate[]) => ArticleCandidate[]) = [];
      responses.forEach((response) => results.push({ ...response.data }));
      results.sort((a, b) => a.total_penalty - b.total_penalty);
      setArticles((prev: any) => [results, ...prev]);
      toast.update(id, {
        render: 'Page generated',
        type: 'success',
        isLoading: false,
        autoClose: 3000
      });
      setLoading(false);
    } catch (error) {
      toast.update(id, {
        render: 'Something went wrong',
        type: 'error',
        isLoading: false,
        autoClose: 3000
      });
      setLoading(false);
      setFile(() => ({ articles: [] }));
      console.log('error', error);
    }
  }

  useEffect(() => {
    async function prepareData(item: any) {
      try {
        const articlesForUnity = item.data.filter(
          (obj: { type: string }) =>
            obj.type != '1sp_Print' && obj.type != '1col_Print' && obj.type != ''
        );
        const responses = await Promise.all(
          articlesForUnity.map((item: { unityId: string }) => {
            if (item.unityId) {
              return getUnityArticle(item.unityId);
            }
          })
        );
        const preparedArticles = responses.map((obj) => {
          if (obj && obj.data) {
            const currentArticle = articlesForUnity.find(
              (article: { id: any }) => article.id == obj.data.printMetadata.desknetElementId
            );

            if (!obj.data.printMetadata.type) {
              throw new Error('Article type is missing');
            }

            let article = {
              ...addSrcToImage(obj.data.article),
              articletype: printMetadataType[obj.data.printMetadata.type as string],
              contentStatus: obj.data.metadata.contentStatus,
              textLenght: currentArticle.textLenght,
              type_code: obj.data.printMetadata.type,
              unity_article_id: currentArticle.unityId,
              studio_layout_id: item.main.studio_layout_id,
              additional_image: currentArticle.additional_image
            };

            if (currentArticle.graph.height && currentArticle.graph.width) {
              article = {
                ...article,
                graph: currentArticle.graph
              };
            }
            return article;
          }
          return;
        });

        const emptyArticle = item.data.filter(
          (obj: { type: string }) => obj.type === '1sp_Print' || obj.type === '1col_Print'
        );
        preparedArticles.push(
          ...emptyArticle.map((obj: any) => {
            return {
              version: '2.3',
              data: {
                content: []
              },
              articletype: 'empty',
              contentStatus: obj.status,
              textLenght: obj.textLenght,
              type_code: obj.type,
              unity_article_id: '',
              studio_layout_id: item.main.studio_layout_id,
              additional_image: obj.additional_image
            };
          })
        );

        let preparedData: any = {
          articles: preparedArticles.filter((obj) => obj),
          pageMetadata: {
            pageName: item.main.itemName,
            pageNumber: item.main.folio,
            date: item.main.itemDate
          }
        };
        if (item.main.adPosition) {
          preparedData = {
            ...preparedData,
            ads: [
              {
                height_mm: item.main.adPosition.height,
                width_mm: item.main.adPosition.width,
                x: item.main.adPosition.x,
                y: item.main.adPosition.y
              }
            ]
          };
        }
        handleGenerate(preparedData);
        setPreparedGenerationData(preparedData);
      } catch (error) {
        console.log('error', error);
        toast.error('The article file is not properly formatted, or some data is missing');
      }
    }

    if (item) {
      prepareData(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSendToInDesign() {
    if (selectedArticle?.printInstructions_url) {
      const id = toast.loading('Sending page instructions to InDesign!');
      sendToInDesign(selectedArticle?.printInstructions_url)
        .then(() => {
          toast.update(id, {
            render: 'Page sent successfully to InDesign',
            type: 'success',
            isLoading: false,
            autoClose: 3000
          });
        })
        .catch((error) => {
          toast.update(id, {
            render: 'Failed to send page to InDesign',
            type: 'error',
            isLoading: false,
            autoClose: 3000
          });
          console.error('Error sending page to InDesign:', error);
        });
    }
  }

  function handleRegenerate(data: any) {
    handleGenerate(data);
    setIsModalOpen(false);
  }

  const hasArticles = articles?.length > 0;

  return (
    <>
      <Layout />
      <div className={`main-container ${hasArticles ? 'main-container--fitContent' : ''}`}>
        {loading ? <Loader /> : ''}
        {hasArticles && (
          <>
            <section className="main-container__action-bar">
              <div className="main-container__action-bar__section">
                <Button
                  text="Regenerate"
                  disabled={loading}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                />
                <Button
                  text="Send to InDesign"
                  onClick={() => {
                    trackButtonClick('Send to InDesign', 'Send Layout to InDesign');
                    handleSendToInDesign();
                  }}
                  disabled={!selectedArticle || loading}
                />
              </div>
            </section>
            {articles.map((article: any, i: React.Key | null | undefined) => {
              return (
                <ShowPDF
                  key={i}
                  index={i}
                  pdfs={article}
                  getArticle={setSelectedArticle}
                  loading={loading}
                />
              );
            })}
          </>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Additional Regeneration Options"
      >
        <RegenerateModal
          data={preparedGenerationData || { articles: [] }}
          layouts={articles[0]}
          onDone={(data) => handleRegenerate(data)}
        ></RegenerateModal>
      </Modal>
    </>
  );
};

export default Layouts;
